/**=====================
    3.26 Landing CSS Start
==========================**/
/* MESH LOADER */
::selection {
  background-color: $primary-color;
  color: $white;
}

::-moz-selection {
  background-color: $primary-color;
  color: $white;
}

$ease-out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$ease-out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000);

html:hover {
  .cursor {
    opacity: 1;
  }
}
.landing-page{
  div {
    canvas:first-child {
      display: none !important;
    }
  }
}


.cursor {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  opacity: 0;
  transition: opacity .2s $ease-out-quart;
  pointer-events: none;

  &--hover {
    .cursor-inner {
      transform: scale(.5);
      opacity: 0;
    }

    .cursor-outer {
      transform: scale(1.4);
      border-color: #7A70BA;
      opacity: 1;
    }
  }
}

.cursor-move-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin-top: -3px;
  margin-left: -3px;
}

.cursor-move-outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cursor-inner {
  display: block;
  width: 80%;
  height: 80%;
  background: #7A70BA;
  border-radius: 50%;
  transition: transform .4s $ease-out-quint, opacity .4s $ease-out-quart;
}

.cursor-outer {
  display: block;
  width: 70%;
  height: 70%;
  border: 1px solid rgba(#7A70BA, 1);
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px 4px rgba(#7A70BA, 0.22);
  transition: border .4s $ease-out-quart, transform .4s $ease-out-quint, opacity .4s $ease-out-quart;
}

// start tap-top

$color_1: rgba(0, 0, 0, 0.3);

@-webkit-keyframes border-transform {

  0%,
  100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }

  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }

  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }

  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }

  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }

  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }

  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

/* #Progress
================================================== */
.blok {
  &:nth-of-type(odd) {
    background-color: $white;
  }

  &:nth-of-type(even) {
    background-color: $primary-color;
  }
}

.progress-wrap {
  position: fixed;
  right: 30px;
  bottom: 30px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba($primary-color, 0.1);
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;

  &::after {
    position: absolute;
    font-family: 'FontAwesome';
    font-weight: 600;
    content: '\f106';
    text-align: center;
    line-height: 46px;
    font-size: 24px;
    color: $primary-color;
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }

  &:hover {
    &::after {
      opacity: 0;
    }

    &::before {
      opacity: 1;
    }
  }

  &::before {
    position: absolute;
    font-family: 'FontAwesome';
    content: '\f106';
    font-weight: 600;
    text-align: center;
    line-height: 46px;
    font-size: 24px;
    opacity: 0;
    background: $primary-color;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 2;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }

  svg {
    path {
      fill: none;
    }
  }

  svg.progress-circle {
    path {
      stroke: rgba($primary-color , 0.4);
      stroke-width: 4;
      box-sizing: border-box;
      -webkit-transition: all 200ms linear;
      transition: all 200ms linear;
    }
  }
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

// end tap-top


@keyframes mesh {
  0% {
    transform-origin: 50% -100%;
    transform: rotate(0);
  }

  50% {
    transform-origin: 50% -100%;
    transform: rotate(360deg);
  }

  50.1% {
    transform-origin: 50% 200%;
    transform: rotate(0deg);
  }

  100% {
    transform-origin: 50% 200%;
    transform: rotate(360deg);
  }
}

.mesh-loader {
  overflow: hidden;
  height: inherit;
  width: inherit;

  .circle {
    position: absolute;
    background: $primary-color;
    border-radius: 50%;
    margin: -3px;
    animation: mesh 3s ease-in-out infinite -1.5s;
    width: 6px;
    height: 6px;
  }

  >div .circle:last-child {
    animation-delay: 0s;
  }

  >div {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  >div:last-child {
    transform: rotate(90deg);
  }
}

.d-flex {
  display: flex;
  align-items: flex-start;

  .flex-grow-1 {
    flex: 1;
  }
}

@-webkit-keyframes animloader14 {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes animloader14 {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

section {
  .title {
    margin-bottom: 35px;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s ease;

    h5 {
      font-family: $landing-cursive;
      font-size: calc(18px + 8 * (100vw - 300px) / 1620);
      font-weight: 700;
      color: $tertiary-color;
      line-height: 1;
    }

    h2 {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 0;
      @media (max-width: 575px) {
        font-size: 24px;
      }
    }

    p {
      color: $light-text-color;
      font-weight: 500;
      width: 78%;
      margin: 0 auto;
    }
  }
}

.landing-page {
  $body-font-color: $theme-body-font-color;
  color: $body-font-color;

  .accordion-item {
    border: none;
  }

  .accordion-button {
    &:not(.collapsed) {
      box-shadow: none;
    }
  }

}

.animate-slider {
  .slick-slide {
    img {
      width: 100%;
    }
  }
}

.f-light {
  color: #52526C;
  opacity: 0.8;
}



.rating-title {
  font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1920 - 300)));
  font-weight: normal;
}

.layout {
  h5 {
    margin-bottom: 10px;
    opacity: .8;
    letter-spacing: 1.2px;
  }

  .btn {
    padding: 0.15rem 0.5rem;
  }
}

.common-card {
  background: #FCFCFD;
  border: 2px dashed $white;
  box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
  border-radius: 10px;
}
 
.boxed {
  background-image: linear-gradient(90deg, rgb(122 112 186 / 10%) 20.35%, rgb(72 163 215 / 10%) 50%, rgb(201 94 158 / 10%) 100%);
}

.faq-section {
  background-image: url(../images/landing/bg-1.png);
  background-size: cover;

  .d-flex {
    display: flex;
    align-items: center;
    gap: 26px;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0px;
    }

    .flex-shrink-0 {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $white;
      border-radius: 10px;
      box-shadow: rgba(30, 34, 40, .02) 0 2px 1px, rgba(30, 34, 40, .02) 0 4px 2px, rgba(30, 34, 40, .02) 0 8px 4px, rgba(30, 34, 40, .02) 0 16px 8px, rgba(30, 34, 40, .03) 0 32px 16px;
    }

    .flex-grow-1 {
      text-align: start;

      h3 {
        font-family: $font-outfit;
        font-size: 18px;
        font-weight: 600;
        color: $theme-body-font-color;
      }

      p {
        font-weight: 500;
        color: $light-text-color;
      }
    }
  }

  .faq-img {
    @media (max-width: 991px) {
      padding-top: 30px;
    }

    img {
      position: relative;
      margin-top: -52px;
      margin-bottom: -34px;
      @media (max-width: 767px) {
        margin: -20px 0;
      }
      @media (max-width: 480px) {
        margin-bottom: -10px;
      }
    }

    .faq-bg {
      position: absolute;
      bottom: -182px;
      right: -144px;
      z-index: -1;

      @media (max-width: 1660px) {
        display: none;
      }
    }
  }
}

.demo-section {
  .demo-box {
    background-image: linear-gradient(45deg, rgb(122 112 186 / 12%) 20.35%, rgb(72 163 215 / 10%) 50%, rgb(201 94 158 / 12%) 100%);
    padding: 20px;
    border-radius: 20px;
    transition: all;

    .demo-title {
      padding-top: 20px;
      text-align: center;

      .btn {
        text-transform: capitalize;
      }
    }

    .img-wrraper {
      border-radius: 10px;
      overflow: hidden;
      position: relative;

      img {
        transition: all 0.5s ease;
        width: 100%;
      }
    }

    &:hover {
      background-image: linear-gradient(45deg, rgb(201 94 158 / 12%)20.35%, rgb(122 112 186 / 10%) 50%, rgb(72 163 215 / 12%) 100%);

      .img-wrraper {
        img {
          transform: scale(1.02);
        }
      }
    }
  }

  .demo-block {
    margin-bottom: -30px;
    justify-content: center;

    >div {
      margin-bottom: 30px;
    }
  }
}

.landing-footer {
  background-image: url(../images/landing/home-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .footer-contain {
    text-align: center;

    img {
      margin-bottom: 5px;
    }

    h2 {
      font-size: calc(18px + (35 - 18) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 700;
      margin-bottom: 10px;
      line-height: 1.4;
      color: $white;
    }

    .star-rate {
      margin-bottom: 40px;

      li {
        display: inline-block;

        i {
          font-size: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }

    .btn-footer {
      a {
        &:nth-child(n + 2) {
          margin-left: 10px;
        }

        margin-top: 8px;
      }
    }
  }
}

@media (max-width: 991px) {
  .landing-home {
    .landing-footer {
      .footer-contain {
        img {
          height: 100px;
        }

        .star-rate {
          margin-bottom: 40px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .landing-home {
    .landing-footer {
      .footer-contain {
        img {
          height: 80px;
          margin-bottom: 10px;
        }

        h2 {
          margin-bottom: 5px;
        }

        p {
          margin-bottom: 10px;
        }

        .star-rate {
          margin-bottom: 25px;
        }

        .btn-footer {
          .btn {
            padding: 10px 25px;
            font-size: 14px;
            line-height: 1;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .landing-home {
    .landing-footer {
      .footer-contain {
        .star-rate {
          margin-bottom: 20px;
        }

        img {
          height: 60px;
        }
      }
    }

    .landing-footer {
      .footer-contain {
        .btn-footer {
          a:nth-child(n + 2) {
            margin-left: 5px;
          }

          a:last-child {
            margin-top: 10px;
          }

          .btn {
            padding: 10px 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 410px) {
  .landing-home {
    .landing-footer {
      .footer-contain {
        .btn-footer {
          a {
            &:last-child {
              margin-top: 10px !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {

  .landing-home {

    //demo section
    .demo-section {
      .demo-block {
        margin-bottom: -20px;

        >div {
          margin-bottom: 20px;
        }
      }

      .demo-box {
        width: fit-content;
        margin: auto;

        .img-wrraper {
          ul.demo-link {
            li {
              &:nth-child(n + 2) {
                margin-left: 5px;
              }

              a {
                font-size: 10px;
              }
            }
          }
        }

        .demo-title {
          h3 {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .landing-home {
    .demo-block {
      .demo-box {
        margin: 0 15px;
      }
    }
  }
}

@media (max-width: 450px) {
  .landing-home {
    .demo-block {
      .demo-box {
        margin: 0 12px;
      }
    }
  }
}

@media (max-width: 405px) {
  .demo-block {
    .demo-box {
      margin: 0 12px;
    }
  }
}

.customer-wrap {
  position: relative;

  .line {
    position: absolute;
    top: 30%;
    z-index: -1;
    animation: bounce-effect 3s infinite ease-in;

    @media (max-width: 1400px) {
      width: 1000px;
      left: 42px;
    }

    @media (max-width: 1199px) {
      width: 600px;
      left: 190px;
    }

    @media (max-width: 991px) {
      width: 472px;
      left: 125px;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }

  .col-md-3.col-6 {
    &:nth-child(odd) {
      margin-bottom: 30px;

      @media (max-width: 767px) {
        margin-bottom: 0px;
      }
    }
    &:nth-child(even) {
      margin-top: 18px;

      @media (max-width: 767px) {
        margin-top: 0px;
      }
    }
  }
}


.customer-box {
  position: relative;
  background-color: #f7f7f7;
  text-align: center;
  padding: 40px 30px;
  border-radius: 15px;
  box-shadow: 0px 0px 33px rgba(119, 119, 119, 0.25);
  transition: all .4s ease-in-out;
  transform: translate(0);

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    height: 0;
    width: 100%;
    border-radius: 10px;
    transition: .5s ease;
    background-color: $primary-color;

  }

  &:hover {
    transform: translateY(-8px);

    &::before {
      height: 100%;
    }

    h6 {
      color: $white;
    }
  }

  h6 {
    transition: all .4s ease-in-out;
  }

  @media (max-width: 1399px) {
    padding: 40px 35px;
  }

  @media (max-width: 1199px) {
    padding: 30px 25px;
  }

  @media (max-width: 991px) {
    padding: 17px 13px;
  }

  @media (max-width: 575px) {
    box-shadow: 12px 12px 12px rgba(119, 119, 119, 0.25);
  }

  img {
    height: 70px;
    width: auto;
    position: relative;
    z-index: 1;

    @media (max-width: 991px) {
      height: 50px;
    }
  }
}

.customer-wrapper {
  position: relative;
}

.customer-wrap {
  margin-bottom: -11px;

  @media (max-width: 1199px) {
    margin: 0 -15px;
  }

  @media (max-width: 991px) {
    margin: 0 -10px;
  }

  @media (max-width: 767px) {
    margin: -12px;
  }

  >div {
    padding: 0 28px;

    @media (max-width: 1199px) {
      padding: 0 15px;
    }

    @media (max-width: 991px) {
      padding: 0 10px;
    }

    @media (max-width: 767px) {
      padding: 12px;
    }
  }

  >div {
    &:last-child {
      @media (max-width: 1199px) {
        .outline-box {
          width: 100%;
        }
      }
    }
  }
}


.framework-box {
  position: relative;
  display: flex;
  align-items: center;
  background: #FCFCFD;
  border: 2px dashed $white;
  box-shadow: 0px 5px 20px rgba(46, 35, 94, 0.12);
  border-radius: 10px;
  padding: 18px 16px;
  gap: 16px;
  margin-left: 30px;
  transition: all 0.5s;
  @media (max-width: 575px) {
    margin-left: 0;
    flex-direction: column;
  }

  &::after {
    position: absolute;
    content: '';
    width: calc(100% + 4px);
    height: 47px;
    border-bottom: 1px dashed rgba(0, 2, 72, 0.2);
    right: -1px;
    border-right: 1px dashed rgba(0, 2, 72, 0.2);
    border-left: 1px dashed rgba(0, 2, 72, 0.2);
    border-radius: 10px;
    top: 81px;
    bottom: 0px;

    @media (max-width: 1399px) {
      top: 59px;
    }

    @media (max-width: 1199px) {
      display: none;
    }
  }

  .frame-icon {
    position: relative;
    min-width: 55px;
    height: 55px;
    background: $white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -32px;
    margin-top: -100px;

    @media (max-width: 575px) {
      min-width: 52px;
      height: 52px;
      margin: 0;
    }

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      box-shadow: 3px 4px 5px rgba(46, 35, 94, 0.11), inset 3px 0px 1px rgba(204, 204, 215, 0.5);
      border-radius: 100%;
    }

    img {
      width: 30px;
      height: 30px;

      @media (max-width: 575px) {
        width: 25px;
        height: 25px;
      }
    }
  }

  .frame-details {
    position: relative;
    text-align: left;
    @media (max-width: 575px) {
      text-align: center;
    }

    h5 {
      transition: all 0.5s;
    }

    p {
      opacity: 0.7;
      margin-bottom: 0;
    }

    &::before,
    &::after {
      position: absolute;
      content: '';
      width: 8px;
      height: 8px;
      background: #FEFEFF;
      border: 1px dashed rgba(0, 2, 72, 0.2);
      border-radius: 100%;
      right: 17px;

      @media (max-width: 1399px) {
        top: 40px;
      }

      @media (max-width: 1199px) {
        display: none;
      }
    }

    &::before {
      top: 60px;
      right: -20px;
      @media (max-width: 1399px) {
        top: 40px;
      }
    }

    &::after {
      bottom: 19px;
      left: -60px;
    }
  }

  &:hover {
    @media (min-width: 576px) {
      transform: scale(1.05);
      transition: all 0.5s;
    }

    .frame-icon {
      &::before {
        animation-name: rotate;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }

    .frame-details {
      h5 {
        color: var(--theme-default);
        transition: all 0.5s;
      }

    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.customization-wrap {
  .landing-title {
    p {
      width: 100%;
    }
  }
}



.sub-title {
  font-size: calc(18px + (26 - 18) * ((100vw - 300px) / (1920 - 300)));
  font-family: $landing-cursive;
  font-weight: 700;
  color: $tertiary-color;
}

.rotate-title {
  color: #FF5A82;
}

.landing-home {
  scroll-behavior: smooth;
  overflow: hidden;
  background-color: $white;

  .home-bg {
    padding: 94px 0 110px;
    border-radius: 30px;
    background-image: url(../images/landing/home-bg.png);
    margin: 78px 43px 64px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    position: relative;
    overflow: hidden;

    @media (max-width: 578px) {
      margin: 78px 15px 64px;
    }

    ul {
      li {
        position: absolute;

        &:first-child {
          top: 26%;
          left: 43%;
          animation: bounce-effect 3s infinite ease-in
        }

        &:nth-child(2) {
          bottom: 19%;
          left: 36%;
          animation: rounded 1.5s infinite linear;
        }

        &:nth-child(3) {
          top: 4%;
          right: 24%;
          animation: rounded 2s infinite linear;
        }

        &:nth-child(4) {
          bottom: 6%;
          left: 21%;
        }

        &:nth-child(5) {
          top: 12%;
          left: 15%;
        }

        &:last-child {
          bottom: 4%;
          right: 10%;
          animation: bounce-effect 2s infinite ease-in
        }
      }
    }

    .home-text {
      padding: 0 94px 0 119px;

      @media (max-width: 1830px) {
        padding: 0px 75px 0 90px;
      }

      @media (max-width: 1400px) {
        padding: 0px 50px 0 63px;
      }

      @media (max-width: 1200px) {
        padding: 0px 28px 0 30px;
      }

      @media (max-width: 578px) {
        padding: 0px 15px 0 15px;
      }

      .main-title {
        border-radius: 30px;
        background: linear-gradient(90deg, rgba(72, 164, 215, 0.40) 0%, rgba(72, 164, 215, 0.00) 100%);
        backdrop-filter: blur(2px);
        padding: 8px 15px;
        width: fit-content;
        margin-bottom: 15px;

        .d-flex {
          .flex-grow-1 {
            p {
              font-size: 18px;
              font-family: $font-outfit;
              color: $secondary-color;
              font-weight: 500;

              @media (max-width: 388px) {
                font-size: 13px;
              }
            }
          }
        }
      }

      h2 {
        color: $white;
        font-family: $font-outfit;
        font-size: calc(23px + (68 - 23) * ((100vw - 768px) / (1920 - 768)));
        font-weight: 600;
        margin-bottom: 10px;
        position: relative;
        z-index: 2;

        @media (max-width: 768px) {
          font-size: 38px;
        }

        @media (max-width: 672px) {
          font-size: 31px;
        }

        @media (max-width: 578px) {
          font-size: 30px;
        }

        @media (max-width: 342px) {
          font-size: 28px;
        }

        span {
          padding-right: 10px;
          background: linear-gradient(90deg, #7A70BA 20.35%, #48A3D7 50%, #C95E9E 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .line-text {
          position: absolute;
          top: 56%;
          left: 50%;
          z-index: -1;
          animation: arrowsvg 3s infinite;

          @media (max-width: 1830px) {
            left: 44%;
          }

          @media (max-width: 1532px) {
            width: 174px;
            top: 58%;
          }

          @media (max-width: 1400px) {
            width: 129px;
          }

          @media (max-width: 1199px) {
            left: 36%;
            width: 122px;
          }

          @media (max-width: 991px) {
            left: 0%;
            width: 98px;
            top: 88%;
          }

          @media (max-width: 892px) {
            width: 68px;
          }

          @media (max-width: 892px) {
            width: 104px;
          }

          @media (max-width: 479px) {
            display: none;
          }
        }
      }

      p {
        font-size: 17px;
        font-family: $font-outfit;
        font-weight: 400;
        color: rgba($white, 0.62);
        margin-bottom: 34px;
      }

      .docutment-button {
        a {
          svg {
            fill: $white !important;
            margin-right: 6px;
            vertical-align: -4px;
            height: 20px;
            width: 20px;
          }

          &:first-child {
            padding: 10px 12px;
            font-size: 16px;
            font-weight: 500;
            font-family: $font-outfit;
            margin-right: 10px;
            transition: all .4s;

            &:hover {
              color: $white !important;
            }
          }

          &:last-child {
            padding: 10px 13px;
          }
        }
      }
    }

    .home-screen {
      position: relative;
      padding-right: 80px;

      @media (max-width: 991px) {
        padding-right: 30px;
      }

      @media (max-width: 767px) {
        display: none;
      }

      .screen-1 {
        transform: rotate(-5.42deg);

        img {
          border: 5px solid $white;
          border-radius: 16px;
          box-shadow: 0px 4px 63px 0px rgba(0, 0, 0, 0.25);

        }
      }

      .screen-2 {
        position: absolute;
        top: 25px;
        right: 74px;
        filter: drop-shadow(-13px 8px 41px rgba(42, 54, 80, 0.25));
        transform: rotate(-5.42deg);

        @media (max-width: 1820px) {
          right: 24px;
        }

        @media (max-width: 1610px) {
          right: 63px;
          width: 236px;
        }

        @media (max-width: 1400px) {
          right: 30px;
          width: 220px;
          top: 16px;
        }

        @media (max-width: 1328px) {
          width: 200px;
        }

        @media (max-width: 1199px) {
          width: 180px;
          right: 39px;
          top: 12px;
        }

        @media (max-width: 991px) {
          left: -38px;
          top: -52px;
        }

        @media (max-width: 870px) {
          left: -38px;
          top: -63px;
          width: 160px;
        }

        img {
          border-radius: 10px;
          animation: bounce-effect 2s infinite ease-in;
        }

      }

      .screen-3 {
        position: absolute;
        bottom: -58px;
        left: 278px;
        filter: drop-shadow(-32px -13px 41px rgba(42, 54, 80, 0.25));
        transform: rotate(-5.42deg);

        @media (max-width: 1610px) {
          width: 236px;
        }

        @media (max-width: 1400px) {
          width: 220px;
        }

        @media (max-width: 1328px) {
          width: 200px;
          bottom: -45px;
          left: 219px;
        }

        @media (max-width: 1199px) {
          width: 180px;
        }

        @media (max-width: 991px) {
          bottom: -63px;
          left: 122px;
        }

        @media (max-width: 870px) {
          bottom: -81px;
          left: 72px;
          width: 160px;
        }


        img {
          border-radius: 10px;
          animation: bounce-effect 3s infinite ease-in;
        }

      }
    }
  }

  .sub-title {
    font-size: calc(18px + (30 - 18) * ((100vw - 300px) / (1920 - 300)));
  }

  .content {
    h1 {
      color: $white;
      line-height: 1.2;
      font-size: calc(25px + (60 - 25) * ((100vw - 300px) / (1920 - 300)));
      text-transform: uppercase;

      span {
        background: linear-gradient(90deg, #FFB905 20.35%, #6AEBAD 50%),
          linear-gradient(0deg, #FFFFFF, #FFFFFF);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    p {
      font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1920 - 300)));
      font-weight: 500;
      font-family: $font-merriweather;
      color: $white;
      opacity: 0.8;
      width: 64%;
      margin: 0 auto;

      @media (max-width: 1366px) {
        width: 90%;
      }

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .content-title {
    position: relative;
    display: inline-block;

    .arrow-decore {
      position: absolute;
      left: -203px;
      top: -25px;

      @media (max-width: 1660px) {
        left: -166px;
        width: 150px;
      }

      @media (max-width: 991px) {
        left: -125px;
        width: 110px;
        top: -14px;
      }

      @media (max-width: 575px) {
        left: -61px;
        width: 60px;
        top: -4px;
      }
    }
  }

  .sticky-header {
    header {
      .nav-padding {
        padding: 16px 50px;
      }
    }
  }
}




@keyframes animationtop {
  0% {
    transform: translate(-10px, -5px);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-10px, -5px);
  }
}

@keyframes animationleft {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(20px, 0);
  }

  100% {
    transform: translate(0, 0px);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


$landing-color : var(--theme-default);

.navbar {
  justify-content: flex-start;

  .navbar-toggler {
    margin-left: auto;
  }
}

.navbar-nav {
  align-items: center;

  .nav-item {
    position: relative;

    .nav-link {
      font-size: 18px;
      font-family: $font-merriweather;
      font-weight: 400;
      letter-spacing: 1px;
      padding: 12px 20px;
      transition: all 0.3s ease;

      &:hover {
        color: var(--theme-default) !important;
        transition: all 0.3s ease;
      }
    }
  }
}

.landing-page {
  .navbar-expand-xl {
    .navbar-collapse {
      background-color: transparent;
    }
  }

  .page-body-wrapper {
    min-height: 100vh;
    background: #eaf4ff;
    overflow: hidden;
  }

  .buy-btn {
    font-size: 16px;
    font-family: $font-merriweather;
    font-weight: 500;
    background: var(--theme-default);
    border-radius: 4px;
    padding: 12px 27px;
    margin-left: 10px;

    @media (max-width: 991px) {
      padding: 10px 24px;
    }

    @media (max-width: 767px) {
      padding: 9px 20px;
      font-size: 14px;
    }

    a {
      color: $white;
      padding: 0;
    }
  }

  .navbar-nav {

    .nav-item {

      .nav-link {
        letter-spacing: 1.5px;
        color: #1F2F3E;
      }
    }
  }

  .sticky-header {
    header {
      background: transparent;
      box-shadow: none;
      z-index: 100;
      left: 0;
      height: auto;

      &.sticky {
        margin-top: 0;

        .navbar-nav .nav-item .nav-link {
          font-size: 16px;
          padding: 16px 15px;
        }

      }
    }
  }
}

.navbar-expand-xl {
  .navbar-brand {
    padding-left: 18px;
  }

  .navbar-collapse {
    flex-flow: row nowrap;
    justify-content: flex-end;
    background-color: $white;
  }
}

.section-space {
  position: relative;
  padding: 80px 0;
  text-align: center;

  p {
    font-size: 15px;
    line-height: 1.5;
  }
}

.landing-main-title {
  margin-bottom: 30px;
}



.bottom-section-grad {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #EDF7FF -3.32%, rgba(227, 242, 255, 0) 144.14%);
    opacity: 0.7;
    height: 100%;
    width: 100%;
  }
}

.pricing-box {
  background: rgba(243, 245, 245, 0.5);
  border: 2px dashed $white;
  border-radius: 10px;
  padding: 30px 20px;

  img {
    width: 23px;
    height: 22px;
  }

  @media (max-width: 767px) {
    padding: 20px 15px;
  }

  p {
    color: #52526C;
  }

  h4 {
    margin-bottom: 20px;

    @media (max-width: 575px) {
      margin-bottom: 15px;
    }
  }

  p {
    line-height: 1.5;
  }

  .purchase-details {
    background: #FCFCFD;
    border: 2px dashed $white;
    box-shadow: 0px 4px 20px rgba(46, 35, 94, 0.05);
    border-radius: 10px;
    padding: 24px 40px;
    margin-top: 20px;

    @media (max-width: 1199px) {
      padding: 24px 20px;
    }

    h2 {
      margin-bottom: 14px;
    }

    .btn-lg {
      font-weight: 500;
      padding: 12px 20px;
      width: 100%;
      min-width: auto;

      @media (max-width: 1199px) {
        padding: 12px 10px;
        font-size: 15px;
      }
    }
  }
}

.license-list {
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 767px) {
    gap: 10px;
  }

  li {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 10px;

    @media (max-width: 991px) {
      gap: 5px;
    }

    @media (max-width: 575px) {
      img {
        width: 18px;
        height: 18px;
      }
    }

    h6 {
      font-weight: normal;
      margin-bottom: 0;
    }
  }
}


.feature-section {
  .feature-box {
    padding: 35px 40px 30px;
    border-radius: 20px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 5px 20px rgba(46, 35, 94, 0.12);
    margin: 40px 0 0px;
    transition: all 0.8s;
    position: relative;
    transform: translateY(0);

    &:hover {
      background: linear-gradient(180deg, rgba($primary-color , 0.2) 0%, rgba(233, 247, 255, 0.1) 100%);
      transform: translateY(-8px);

      .feature-icon {
        &::before {
          opacity: 1;
        }

        &:after {
          opacity: 0;
        }
      }
    }

    .feature-icon {
      width: 75px;
      height: 80px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -80px auto 7px;

      img {
        z-index: 1;
        position: relative;
      }

      &:after {
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        -webkit-transition: all 0.8s;
        -ms-transition: all 0.8s;
        transition: all 0.8s;
        background-repeat: no-repeat;
        background-position: center 2px;
        background-image: url(../images/landing/feature-icon/1.png);
      }

      &:before {
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        opacity: 0;
        -webkit-transition: all 0.8s;
        -ms-transition: all 0.8s;
        transition: all 0.8s;
        background-repeat: no-repeat;
        background-position: center 2px;
        background-image: url(../images/landing/feature-icon/3.png);
      }
    }
  }
}

.rating-title {
  display: flex;
  align-items: center;
  justify-content: center;

  .decore-1 {
    margin-top: -35px;
    animation: animationtop 5s infinite;
  }
}

.ratings {
  li {
    i {
      color: #D77748;
    }
  }
}

.ptb50 {
  padding: 80px 0;

  @media (max-width: 991.98px) {
    padding: 60px 0;
  }

  @media (max-width: 575.98px) {
    padding: 50px 0;
  }
}

.purchase-btn {
  padding: 12px 20px;
  min-width: 200px;
  border: none;

  @media (max-width: 991px) {
    min-width: 160px;
  }

  @media (max-width: 767px) {
    min-width: 140px;
    padding: 10px 17px;
    font-size: 16px;
  }
}

@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1)
  }

  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55)
  }

  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1)
  }
}


.landing-page {
  .bg-Widget {
    background: linear-gradient(180deg, #F7F8F9 69%, rgba(247, 248, 249, 0) 91.83%);

    &:before {
      top: 220px !important;
      transform: rotate(-40deg);
    }

    &:after {
      top: unset !important;
      bottom: 0 !important;
    }

  }
}

.btn-md:hover {
  animation: 0.3s pulse 3;
}

.tap-top {
  text-align: center;
  cursor: pointer;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 5;
  color: #fff;
  background: var(--theme-default);
  border: none;
  padding: 7px;
  box-shadow: 0 0 10px var(--theme-default);
  opacity: 0.5;
  transition: all 0.3s ease;

  &:hover {
    transition: all 0.3s ease;
    opacity: 1;
  }

  svg {
    width: 20px;
  }
}

@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

.page-wrapper,
.landing-page {
  overflow: hidden;

  .section-space {
    padding: 80px 0;
  }


  .mofi-demo-section {
    position: relative;

    .img-effect {
      -webkit-box-shadow: 0 0 37px rgba(8, 21, 66, 0.15);
      box-shadow: 0 0 37px rgba(8, 21, 66, 0.15);
      border-radius: 15px;
      padding: 20px;
      position: relative;

      .hover-link {
        position: absolute;
        height: 100%;
        width: 100%;
        background: linear-gradient(10deg, rgba($dark-color, 0.8) 0%, transparent 80%);
        border-radius: 15px;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 20px;
        opacity: 0;
        transition: all 0.3s ease;
        left: 0;
        top: 0;

      }

      &:hover {
        .hover-link {
          opacity: 1;
          z-index: 1;
          transition: all 0.3s ease;
        }
      }

      img {
        width: 100%;
        transition: all 0.3s ease;
      }

      &:hover {
        box-shadow: 0 0 37px rgba(8, 21, 66, 0.15);
        transition: all 0.3s ease;

        img {
          transform: scale(1.01);
          transition: all 0.3s ease;
        }
      }
    }
  }
}

.landing-page {
  overflow: unset;
}

.fluid-space {
  padding: 0 calc(10px + (80 - 10) * ((100vw - 300px) / (1920 - 300)));

  @media (max-width: 1399px) {
    padding: 0 30px;
  }

  @media (max-width: 575px) {
    padding: 0 12px;
  }
}

ul.dot-group {
  display: flex;
  gap: 6px;
  padding: 15px 10px 0;

  li {
    width: 8px;
    height: 8px;
    background: #E8E8EC;
    border-radius: 100%;
  }
}

.demo-imgs {
  margin: -10px;

  .demo-content {
    margin-top: 0px;

    .mofi-demo-img {
      color: $primary-color;
      font-weight: 700;
      display: inline-block;
      border-radius: 14px;
      background: $white;
      transition: all 0.3s ease;
      justify-content: center;
      box-shadow: 7px 7px 5px rgba(192, 202, 216, 0.22);
      position: relative;

      .overflow-hidden {
        padding: 10px;

        img {
          width: 100%;
          -webkit-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }
      }

      &:hover {
        .overflow-hidden {
          img {
            -webkit-transform: scale(1.01);
            transform: scale(1.01);
          }
        }
      }
    }

    &:hover {
      .title-wrapper {
        h3 {
          a {
            color: $primary-color;
          }
        }
      }
    }

    .title-wrapper {
      padding: 20px 10px 10px;

      h3 {
        a {
          font-size: 22px;
          color: $theme-body-font-color;
          transition: all .4s;
        }
      }

      .theme-name {
        position: relative;
        font-size: calc(15px + (20 - 15) * ((100vw - 300px) / (1920 - 300)));
        text-transform: capitalize;
        font-weight: 600;

        span {
          color: #52526C;
        }
      }
    }
  }

  @media (max-width: 1670px) {
    >div {
      padding: 15px;
      margin: 0;
    }
  }
}

.text-marqee {
  height: 230px;

  @media (max-width: 1199px) {
    height: 166px;
  }

  @media (max-width: 991px) {
    height: 130px;
  }

  @media (max-width: 767px) {
    height: 95px;
  }

  @media (max-width: 575px) {
    height: 65px;
  }
}


.flower-gif {
  top: -40px;
  left: 19%;

  @media (max-width: 991px) {
    top: 0;
    left: 15%;
  }

  @media (max-width: 767px) {
    left: 8%;
  }

  @media (max-width: 575px) {
    display: none;
  }
}

.big-title {
  font-weight: 700;
  font-size: 200px;
  line-height: 229px;
  letter-spacing: -0.045em;
  color: rgba(234, 235, 241, 0.5);
  margin-bottom: 0;

  @media (max-width: 1199px) {
    font-size: 140px;
    line-height: 170px;
  }

  @media (max-width: 991px) {
    font-size: 110px;
    line-height: 140px;
  }

  @media (max-width: 767px) {
    font-size: 80px;
    line-height: 100px;
  }

  @media (max-width: 575px) {
    font-size: 50px;
    line-height: 60px;
  }
}

//navbar
.navabr_btn-set {
  position: relative;

  &:focus {
    outline: 0;
  }

  span {
    display: block;
    background-color: $white;
    height: 3px;
    width: 25px;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    left: 0;
    opacity: 1;
  }

  &:not(.custom_nav) {
    span {
      &:nth-child(1) {
        position: absolute;
        left: 12px;
        top: 10px;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        opacity: 0.9;
      }

      &:nth-child(2) {
        height: 12px;
        visibility: hidden;
        background-color: transparent;
      }

      &:nth-child(3) {
        position: absolute;
        left: 12px;
        top: 10px;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
        opacity: 0.9;
      }
    }
  }
}

.navbar-b {
  transition: all .5s ease-in-out;
  background-color: transparent;
  padding-top: 1.563rem;
  padding-bottom: 1.563rem;
}

.fixed-top {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  left: 0;
  z-index: 1030;
  background-color: $white;
}

.navbar-toggler {
  span {
    display: block;
    background-color: $transparent-color;
    height: 3px;
    width: 25px;
    z-index: 112;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    left: 0;
    opacity: 1;

    &:nth-child(1) {
      transition: transform .35s ease-in-out;
    }

    &:nth-child(3) {
      transition: transform .35s ease-in-out;
    }
  }

  &:focus {
    box-shadow: none;
  }
}

.navbar_nav_modify {
  li {
    text-align: center;
  }
}

//sticky header
.sticky-header {
  header {
    position: fixed;
    width: 100%;
    z-index: 6;
    text-align: center;
    line-height: normal;
    height: 80px;
    background: $landing-color;
    color: $white;
    box-shadow: 12px 12px 12px rgba(119, 119, 119, 0.25);
    transition: all 0.4s ease;

    .nav-padding {
      padding: 14px;
    }

    &.sticky {
      line-height: normal;
      background: $landing-color;
      text-align: left;

      .nav-padding {
        padding: 7px 14px;
      }
    }
  }
}

.landing-home {
  .sticky .navbar-brand img {
    max-height: 35px;
  }
}

.title-padding {
  padding: 130px 0;
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-4px);
  }
}

// Landing page responsive css start
@media (max-width: 1440px) {
  .navbar-nav {
    .nav-item {
      .nav-link {
        font-size: 16px;
        padding: 12px 7px;
      }
    }
  }
}

/* ========= responsive scss for 1200 screen ========= */
@media only screen and (max-width: 1199px) {
  .sticky-header header.sticky .nav-padding {
    padding: 15px 25px;
  }

  .navbar-toggler {
    span {
      background-color: $theme-body-font-color;
    }
  }

  .sticky-header {
    .navbar-nav {
      .nav-item .nav-link {
        color: $black;
        padding: 10px 12px !important;
        font-size: 15px;
      }

      li {
        display: block;
        width: 100%;
      }
    }
  }

  .landing-page {

    .navbar-expand-xl {
      .navbar-collapse {
        background-color: white;
        position: absolute;
        width: calc(100% - 30px);
        left: 15px;
        top: 70px;
        border-top: 1px solid $light-gray;
        box-shadow: $card-box-shadow;

        @media (max-width: 575px) {
          top: 64px;
        }

        .navbar-nav {
          padding: 20px 30px 30px 30px;
        }
      }
    }
  }

  header.sticky {
    .navbar-expand-xl {
      .navbar-collapse {
        top: 66px;
      }
    }
  }

  .landing-main {
    .sticky {
      .navbar-expand-xl {
        .navbar-brand {
          top: 8px;
        }

        .navabr_btn-set {
          top: 16px;
        }
      }
    }

    .navbar-toggler {
      span {
        background-color: $white;
      }
    }

    .navbar_nav_modify {
      width: 100vw;
      left: 0;
      position: fixed;
      height: 100vh;
      background-color: #293240;
      top: 0;
      padding: 120px 0px;
    }

    h4 {
      font-size: 20px;
    }

    .navbar-expand-xl {
      .navabr_btn-set {
        position: fixed;
        top: 24px;
        z-index: 1;
        right: 20px;
      }

      .navbar-brand {
        padding-left: 5px;
        z-index: 1;
        position: fixed;
        top: 14px;
      }

      .navbar-collapse {
        .navbar-nav {
          .nav-item {
            a {
              padding: 28px 60px;
              width: 16px;
              margin: 0 auto;
              text-align: center;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }

    .navbar-expand-lg {
      .navbar-brand {
        padding-left: 5px;
      }

      .navbar-collapse {
        .navbar-nav {
          .nav-item {
            a {
              padding: 9px;
            }
          }
        }
      }
    }
  }
}

/* ========= responsive scss for 992 screen ========= */
@media only screen and (max-width: 991px) {
  .landing-home {

    .navbar-brand img {
      height: 35px;
    }
  }

  .frameworks-section {
    >.container {
      max-width: 100%;
    }
  }

  .landing-main {
    .navbar-expand-lg .navbar-collapse {
      position: absolute;
      top: 90px;
      background-color: rgb(255, 255, 255);
      width: calc(100% - 30px);
      border-radius: 5px;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      padding: 15px;
    }
  }

  .landing-main {
    .section-space {
      padding: 60px 0;

      p {
        font-size: 16px;
      }
    }

    .navbar-expand-lg {
      .navbar-toggler {
        background-color: $white;

        .navbar-toggler-icon {
          i {
            margin-top: 5px;
            color: var(--theme-default);
          }
        }
      }

      .navbar-collapse {
        background-color: $white;
        z-index: 111;

        .navbar-nav {
          .nav-item {
            a {
              color: $black;
            }
          }
        }
      }
    }

    .footer-bg {
      h2 {
        font-size: 28px;
      }
    }
  }
}

/* ========= responsive scss for 768 screen ========= */
@media only screen and (max-width: 767px) {
  .landing-page .sticky-header header.sticky .navbar-nav .nav-item .nav-link {
    font-size: 14px;
    padding: 10px 12px;
  }
}

/* ========= responsive scss for 575 screen ========= */
@media only screen and (max-width: 575px) {
  .landing-home .sticky-header header .nav-padding {
    padding: 15px 30px;
  }

  .page-wrapper,
  .landing-page {

    .section-space {
      padding: 40px 0;
    }
  }

  .landing-main {
    h4 {
      font-size: 17px;
    }

    .footer-bg {
      h2 {
        font-size: 21px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {

  .sticky-header header.sticky {
    height: 56px;
  }

  .landing-home {
    .sticky-header header .nav-padding {
      padding: 15px;
    }

    .navbar-toggler {
      border: none;
      background-color: transparent;
      padding: 0;
    }

    .navbar-brand img {
      height: 25px;
    }
  }

}

// @media only screen and (max-width: 380px) {
//   .component-col-set {
//     width: 100%;
//   }
// }


@keyframes arrowsvg {
  0% {
    transform: scale(0);
    transform-origin: left;
  }

  100% {
    transform: scale(1);
    transform-origin: left;
  }
}

@keyframes rounded {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce-effect {
  0% {
    transform: translateY(0);
    transition: 0.5s;
  }

  50% {
    transform: translateY(-5px);
    transition: 0.5s;
  }

  100% {
    transform: translateY(0);
    transition: 0.5s;
  }

}

/**=====================
    3.26 Landing CSS Ends
==========================**/