/**=====================
	2.14 Dropdown CSS Start
==========================**/
.img-cropper {
	.dropup {
		.dropdown-toggle {
			&::after {
				content: '';
				display: none;
			}
		}
	}
}

.dropdown-auto {
	overflow: auto;
}

.dropdown-divider {
	margin: 0;
}

.dropdown {
	.dropdown-toggle {
		background-color: rgba(122, 112, 186, 0.08);
		color: $primary-color;
		border-radius: 10px;
		padding: 5px 10px;
		width: 100px;
		text-align: left;

		&:after {
			position: absolute;
			top: 50%;
			right: 10px;
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
			content: "\f107";
			border: none;
			font-family: "FontAwesome";
			font-size: 18px;
			margin: 0;
		}

		&.btn-primary {
			color: $white;
		}

		&:focus {
			box-shadow: none;
			border: unset;
		}
	}

	&.custom-dropdown {
		.dropdown-toggle {
			border-radius: 3px;
			border: 1px solid rgba($badge-light-color, 20%);
			background-color: $transparent-color;
			color: var(--body-font-color);
			font-weight: 500;

			&::after {
				color: var(--theme-default);
			}
		}

		.dropdown-menu {
			.dropdown-item {
				&:hover {
					background-color: $main-body-color;
					color: var(--theme-default);
					opacity: 1;
				}
			}
		}
	}

	.dropdown-menu {
		font-family: $font-outfit, $font-serif;
	}
}

.docs-toggles {
	.dropdown {
		.dropdown-toggle {
			width: auto;
		}
	}
}

.nav-tabs {
	.dropdown {
		.dropdown-toggle {
			width: 120px;

			@media only screen and (max-width: 575.98px) {
				width: 100%;
				text-align: center;
			}
		}
	}
}

// .dropdown-item {
// 	width: auto !important;
// }

.icon-dropdown {
	.dropdown-toggle {
		background: transparent;
		color: $theme-body-sub-title-color;
		padding: 0;
		width: auto;
		// border: none;

		i {
			font-size: 16px;
		}

		&::after {
			display: none;
		}
	}

	.dropdown-item {
		line-height: 1.5;
	}
}

.dropdown-basic {
	margin-bottom: -10px;

	.btn-group {

		.btn-round {
			border-radius: 50px;
		}
	}

	.separated-btn {
		margin-left: -6px;

		[dir="rtl"] & {
			margin-left: unset;
			margin-right: -6px;
		}

		.btn {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			padding: 10px;
			[dir = "rtl"] & {
				padding: 10px;
			}
		}

		&.dropdown {
			margin-bottom: 0;
		}
	}

	button {
		max-height: 43px;
	}

	.dropdown {
		position: relative;
		display: inline-block;
		margin-bottom: 10px;

		.dropbtn {
			color: white;
			padding: 12px 35px;
			border: none;
			cursor: pointer;
		}

		.dropdown-content {
			display: none;
			position: absolute;
			right: 0;
			background-color: #f9f9f9;
			min-width: 175px;
			box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
			z-index: 1;
			left: 0;
			top: 45px;

			a {
				color: black;
				padding: 12px 16px;
				text-decoration: none;
				display: block;
			}

			.dropdown-header {
				padding: 12px 16px;
			}
		}
	}

	.dropdown-content a:hover {
		background-color: #f1f1f1;
	}

	.dropdown:hover .dropdown-content {
		display: block;
	}
}

.dropup-basic {
	.dropup {
		position: relative;
		display: inline-block;

		.dropbtn {
			color: white;
			padding: 12px;
			border: none;
		}

		.dropup-content {
			display: none;
			position: absolute;
			background-color: #f9f9f9;
			min-width: 170px;
			bottom: 45px;
			z-index: 999;
			left: 0;

			a {
				color: black;
				padding: 12px 16px;
				text-decoration: none;
				display: block;
			}
		}
	}

	.dropup .dropup-content a:hover {
		background-color: $light-gray;
	}

	.dropup:hover .dropup-content {
		display: block;

		a {
			&.active {
				background-color: $light-gray;
			}
		}
	}
}


.dropdown-block {
	li {
		.dropdown-item {
			&:hover {
				background-color: $gray-60;
			}
		}

		.helper-truncate {
			&:hover {
				background-color: unset !important;
			}
		}
	}
}

.dropdown-basic {
	.dropdown {
		.dropdown-content {
			a {
				&:hover {
					background-color: $gray-60 !important;
				}
			}
		}
	}
}

.dropdown-menu {
	.input-group {
		background-color: unset;
		padding: 6px 12px;
		border-top: 1px dashed $light-semi-gray;
		background-color: $white;
		opacity: 0.6;

		&:hover,
		&:active {
			background-color: $gray-60;
		}

		.input-group-text {
			background-color: unset;
			border: none;
		}
	}
}

.dark-form {
	.form-label {
		color: var(--text-gray);
	}

	.form-control {
		border: 1px dashed var(--text-gray) !important;
	}

	.form-check {
		.form-check-input {
			border: 1px dashed var(--text-gray);
		}

		.form-check-label {
			color: var(--text-gray);
		}
	}

	h6 {
		color: var(--text-gray);
	}

	p {
		&.dropdown-item {
			padding: 7px 0 0 0;
			border-top: 1px dashed var(--chart-dashed-border);
		}
	}

	.helper-truncate {
		color: var(--text-gray);
		background-color: transparent;
	}
}

.dropdown-toggle {
	&::after {
		display: inline-block;
		margin-left: 8px;
		vertical-align: 4px;
		content: '';
		border-top: 4px dashed;
		border-right: 4px dashed transparent;
		border-bottom: 0;
		border-left: 4px dashed transparent;

		[dir="rtl"] & {
			margin-left: unset;
			margin-right: 8px !important;
		}
	}
}

.dropstart {
	.dropdown-toggle {
		&::before {
			display: inline-block;
			margin-right: 8px;
			content: '';
			border-top: 4px dashed transparent;
			border-right: 4px dashed;
			border-bottom: 4px dashed transparent;

			[dir="rtl"] & {
				margin-left: 8px;
				margin-right: unset;
			}
		}
	}
}

.dropup {
	.dropdown-toggle {
		&::after {
			content: '';
			display: inline-block;
			margin-left: 8px;
			vertical-align: 4px;
			border-top: 0;
			border-right: 4px dashed transparent;
			border-bottom: 4px dashed;
			border-left: 4px dashed transparent;

			[dir="rtl"] & {
				margin-left: unset;
				margin-right: 8px;
			}
		}
	}
}

.dropend {
	.dropdown-toggle {
		&::after {
			content: '';
			display: inline-block;
			content: '';
			border-top: 4px dashed transparent;
			border-right: 0;
			border-bottom: 4px dashed transparent;
			border-left: 4px dashed;
			margin-left: 8px;

			[dir="rtl"] & {
				margin-left: unset;
				margin-right: 8px;
			}
		}
	}
}

.form-wrapper {
	width: 260px;
}

.dropdown-menu {
	.helper-truncate {
		@media (max-width:400px) {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	.form-control {
		&::placeholder {
			color: $light-text;
			font-size: 14px;
		}
	}
}

.dark-dropdown {
	.dropdown-block {
		li {
			.dropdown-item {

				&:hover,
				&.active {
					background-color: $dark-color;
					color: $white;
				}
			}
		}
	}
}

btn-group {
	.dropdown-wrapper {
		z-index: 2;

		.input-group {
			.input-group-text {
				padding: 0px 8px 0px 0px;

				[dir="rtl"] & {
					padding: 0px 0px 0px 8px;
				}
			}
		}
	}
}

.helper-cards {
	.common-flex {
		.btn-group {
			.dropdown-menu {
				li {
					.dropdown-item {
						&:hover {
							background-color: transparent;
						}
					}
				}
			}
		}
	}
}

.dropdown-option {
	.dropdown {
		.dropdown-toggle {
			color: var(--white);
		}
	}
}

.rtl-dropdown {
	.dropdown-menu {
		li {
			.dropdown-item {
				[dir="rtl"] & {
					text-align: right;
				}
			}
		}
	}

	.btn-group {
		.dropdown-menu {
			[dir="rtl"] & {
				text-align: right;
			}
		}
	}
}

.heading-dropdown {
	.dropdown-menu {
		>li {
			&:first-child {
				>a {
					border-bottom: 4px solid var(--recent-border);
					border-top: 0;
					background-color: $light-background;
				}
			}
		}
	}
}

/**=====================
	2.14 Dropdown CSS Ends
==========================**/