/**=====================
    3.25 Knowledgebase CSS start
==========================**/
.browse {
  .browse-articles {
    span {
      vertical-align: middle;
      // position: relative;

      &:nth-child(2) {
        padding-left: 20px;

        [dir="rtl"] & {
          padding-left: unset;
          padding-right: 20px;
        }
      }

      svg {
        width: 14px;
        height: 14px;
        // margin-right: 10px;
        vertical-align: text-top;
        position: absolute;
        left: 0;
        top: 3px;

        [dir="rtl"] & {
          left: unset;
          right: 0;
        }
      }
    }

    h5 {
      padding-bottom: 10px;
      font-weight: 500;
      margin-bottom: 0px;
      border-bottom: $card-border-color;
      padding-left: 25px;

      [dir="rtl"] & {
        padding-left: unset;
        padding-right: 36px;
      }

      span {
        svg {
          width: 16px;
          height: 16px;
          font-weight: 500;
          vertical-align: text-top;
          left: 7px;

          [dir="rtl"] & {
            left: unset;
            right: 15px;
          }
        }
      }
    }

    ul {
      li {
        padding: 8px;
        border-radius: 8px;

        a {
          color: $theme-body-font-color;
          position: relative;
          display: flex;
          align-items: center;
          gap: 10px;
        }

        &:hover {
          background-color: $light;
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .browse {
    div[class^="col-"] {
      &:nth-child(3) {
        margin-top: 30px;
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .browse {
    .browse-articles {
      ul {
        li {
          a {
            span {
              &:nth-child(2) {
                width: 158px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}

.articles-icons {

  svg {
    min-width: 18px;
  }

  .flex-grow-1 {
    h5 {
      padding-bottom: 6px;
    }
  }
}


.d-flex {
  &.faq-widgets {
    svg {
      min-width: 18px;
    }
  }
}

/**=====================
    3.25 Knowledgebase CSS End
==========================**/