/**=====================
    3.37 Scroll-Spy CSS start
==========================**/

// Navbar scrollspy
.main-scrollspy {
    .nav-pills {
        .nav-link {
            color: var(--chart-text-color);
            font-weight: 500;

            &.active {
                color: $white;
            }
        }
    }

    h6,
    h5 {
        margin-bottom: 6px;
        color: var(--body-font-color);
    }

    p:not(.f-m-light) {
        color: $font-gray-color;
    }

    .navbar-scrollspy {
        .bg-body-tertiary {
            justify-content: space-between;
            background-color: var(--light-background) !important;
        }

        .nav-pills {
            align-items: center;

            .nav-item {
                &.dropdown {
                    .dropdown-toggle {
                        width: 120px;
                        border-radius: 6px;
                        padding: 8px 16px;
                    }

                    .dropdown-menu {
                        li {
                            .dropdown-item {
                                opacity: 1;
                                border: unset;
                                color: var(--text-gray);

                                &.active,
                                &:hover {
                                    background-color: rgba($primary-color, 0.09);
                                    color: var(--theme-default);
                                }
                            }
                        }
                    }
                }
            }
        }

        .scrollspy-example {
            height: 200px;
            overflow-y: auto;
            scroll-behavior: smooth;
            background-color: var(--light-background) !important;
        }
    }

    // Nested Scrollspy
    .nested-scrollspy {
        #navbar-scrollspy2 {
            >nav {
                >a {
                    color: $theme-body-font-color;
                    font-size: 15px;
                    &.active {
                        color: $white;
                    }
                }
            }
        }

        .nested-scrollspy-menu {
            background-color: var(--light-background) !important;

            .nav-pills {
                &:first-child {
                    padding: 22px 16px;
                }
            }
        }

        .scrollspy-example-2 {
            height: 300px;
            overflow-y: auto;
            scroll-behavior: smooth;


            p {
                margin-bottom: 16px;
                line-height: 1.7;
            }

            svg {
                width: 120px;
                height: 150px;
            }
        }
    }

    .scrollspy-example-3 {
        height: 200px;
        overflow-y: auto;
        scroll-behavior: smooth;
    }

    .custom-scrollspy-section {
        .common-p-space {
            padding: 18px 0;

            &:last-child {
                padding-bottom: 0;
            }
        }

        .nav-pills {
            .nav-link {
                position: relative;
                border-radius: 0;
                transition: all .2s ease-in-out;
                text-align: center;
                padding: 12px 16px;

                &.active {
                    .custom-arrow {
                        display: block;
                        position: absolute;
                        content: '';
                        border-left: 8px solid var(--theme-default);
                        border-top: 8px solid transparent;
                        border-bottom: 8px solid transparent;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        right: -12px;
                        transition: all .2s ease-in-out;
                        [dir = "rtl"] & {
                            right: unset;
                            left: -12px;
                            border-left: unset;
                            border-right: 8px solid var(--theme-default);
                            transform: translate(50%, -50%);
                        }
                    }
                }
            }
        }

        .main-project {
            .attendance-card {
                display: flex;
                padding: 15px;
                border-radius: 5px;
                gap: 15px;
                align-items: center;
                .left-overview-content {
                    .svg-box {
                        width: 100px;
                        height: 100px;
                        padding: 6px;
                        background-color: var(--white);
                        box-shadow: 0px 71.2527px 51.5055px rgb(229 229 245 / 19%), 0px 42.3445px 28.0125px rgb(229 229 245 / 15%), 0px 21.9866px 14.2913px rgb(229 229 245 / 13%), 0px 8.95749px 7.16599px rgb(229 229 245 / 10%), 0px 2.03579px 3.46085px rgb(229 229 245 / 6%);
                        border-radius: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg {
                            width: 120px;
                            height: 150px;
                        }
                    }
                }

                .right-overview-content {
                    display: flex;
                    gap: 25px;
                    align-items: center;
                    justify-content: space-between;
                    width: calc(100% - 43px - 15px);
                    >div {
                        h6 {
                            padding-bottom: 0;
                        }
                    }
                    .marks-count {
                        display: flex;
                        gap: 23px;
                        align-items: center;
            
                        sub {
                            bottom: -0.8px;
                        }
            
                        @media (max-width:1700px) {
                            gap: 12px;
                            flex-wrap: wrap;
                        }
            
                        @media (max-width: 480px) {
                            gap: 14px;
                        }
                    }
                }
            }
        }

        .scrollspy-example-5 {
            height: 300px;
            overflow-y: auto;
            overflow-x: hidden;
            scroll-behavior: smooth;

            // .common-align {
            //     padding: 12px 0;
            // }

            img {
                height: 100px
            }

            .about-box {

                >div {
                    svg {
                        width: 13px;
                        height: 13px;
                        vertical-align: -3px;
                        fill: $white;
                    }
                }
            }

            span {
                color: $font-gray-color;
            }
        }
    }

    .experience-section {
        .progress {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            .progress-bar {
                background-color: rgba($primary-color, 0.4);
            }
        }
    }
}


/**=====================
    3.37 Scroll-Spy CSS start
==========================**/