/**=====================
2.6 Breadcrumb CSS Start
==========================**/
.breadcrumb-colored {
	padding: 12px 16px;
	border-radius: 4px;

	li {
		a {
			color: $breadcrumb-anchor-color;
		}

		&.active {
			color: $breadcrumb-anchor-color;
			opacity: 0.7;
		}
	}

	.breadcrumb-item {
		&:before {
			color: $breadcrumb-anchor-color;
		}
	}

	.breadcrumb-item.txt-dark {
		&:before {
			color: $breadcrumb-anchor-dark-color;
		}
	}
}

.breadcrumb-icon {
	--bs-breadcrumb-divider: '>';

	li {
		display: inline-block;
	}
}

.breadcrumb-no-divider {
	--bs-breadcrumb-divider: '';
}

.breadcrumb-item {
	&.active {
		text-transform: capitalize;
		color: $primary-color;
		font-weight: 600;

	}
}

.breadcrumb-space {
	.breadcrumb-item {
		&::before {
			padding-right: 0;
		}
	}
}

/**=====================
	2.6 Breadcrumb CSS ends
==========================**/